import { useState } from 'react';
import {APP_NAME, APP_HEADING,LOADING_STATE, WORD_NOT_FOUND_TRY_AGAIN, WORD_NOT_FOUND, DID_YOU_MEAN, SEARCH_BUTTON, SEARCH_PLACEHOLDER} from "./constants";
import './App.css';

function App() {
  const [wordInput, setWordInput] = useState('');
  const [notFound, setNotFound] = useState('');
  const [didYouMean, setDidYouMean] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [definition, setDefinition] = useState('');
  const [audio, setAudio] = useState('');

  function handleClick(e) {
    e.preventDefault();
    if(wordInput === '') return;
    getData(wordInput);
  };

  function updateWord(e) {
    setWordInput(e.target.value)
  }

  function getSuggestedWord(word) {
    setWordInput(word);
    getData(word);
  }

  async function getData(word) {
    setAudio('');
    setDidYouMean('');
    setNotFound('')
    setDefinition('');
    setSuggestions([]);

    const response = await fetch(`https://www.dictionaryapi.com/api/v3/references/learners/json/${word}?key=${process.env.REACT_APP_DICTIONARY_KEY}`)
    const data = await response.json();

    if(data.length === 0)  {
      setNotFound(WORD_NOT_FOUND_TRY_AGAIN);
      return
    }

    if(typeof data[0] === "string") {
      setNotFound(WORD_NOT_FOUND);
      setDidYouMean(DID_YOU_MEAN);
      setSuggestions(data);
    }
    let definition = data[0].shortdef[0];
    setDefinition(definition)

    const soundName = data[0].hwi.prs[0].sound.audio;
    if(soundName) {
      setAudio(soundName);
    }
  }

  function renderSound(soundName) {
    if(soundName) {
      let soundFolder = soundName.charAt(0);
      let soundSource = `https://media.merriam-webster.com/audio/prons/en/us/mp3/${soundFolder}/${soundName}.mp3?apikey=${process.env.REACT_APP_DICTIONARY_KEY}`
  
      return(
        <audio src={soundSource} autoplay="true" controls="true"/>
      )
    }
  }

  return (
    <div className="App">
      <header className='App-header'>
        <div className="">
          <h1>{APP_NAME}</h1>
        </div>
      </header>
      <section className="main">
        <h2>
          {APP_HEADING}
        </h2>
        <div className="form__wrap">
          <form className="form">
            <input type="text" id="input" placeholder={SEARCH_PLACEHOLDER} onChange={updateWord} value={wordInput} />
            <button id="search" onClick={handleClick}>{SEARCH_BUTTON}</button>
          </form>
        </div>
      </section>
      <section className='data'>
        <p className='def'>{definition ? definition : <span className='hidden'>{LOADING_STATE}</span>}</p>
        <div className={audio ? 'audio' : 'hidden'}>{ renderSound(audio)}</div>
        <div className='not_found'>
            {notFound}
            <div className="did_you_mean">{didYouMean}</div>
            <div className='suggestions'>
              {
              suggestions ? suggestions.map( (suggestion) => <button className="suggested" key={suggestion} onClick={() => getSuggestedWord(suggestion)}>{suggestion}</button>) : ""
              }
            </div>
        </div>
      </section>
    </div>
  );
}

export default App;
